import { useEffect, useState } from "react";
import { AES } from "crypto-js";
import { message } from "antd";
import axios from "axios";

export const URL_ENCODING_KEY = "star-stake@2021-2099";
export const MAIN_APP_BASE_URL =
  process.env.REACT_APP_MAIN_APP_BASE_URL || "https://qa.starstake.com/#/";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES_BASE_URL;
export const IMAGE_PUBLIC_BASE_URL = process.env.REACT_APP_IMAGES_PUBLIC_URL;

export const USER_SERVER = "api/user/";
export const FANSCORE_SERVER = "api/score/";
export const VIDEO_SERVER = "api/video/";
export const COURSE_SERVER = "api/course/";
export const CHAPTER_SERVER = "api/chapter/";
export const RESOURCE_SERVER = "api/resources/";
export const SNFT_SERVER = "api/snft/";
export const COLLECTION_SERVER = "api/collection/";
export const BUCKS_SERVER = "api/bucks/";
export const BUNDLE_SERVER = "api/bundle/";
export const ACCESS_NFT_SERVER = "api/accessNft/";
export const AGENT_SERVER = "api/agent/";
export const NOTI_SERVER = "api/notification/";
export const ACCESS_REQUEST_SERVER = "api/webhook/";
export const MASTER_DATA_SERVER = "api/master-data/";
// export const BUCKS_SERVER = "https://api.starstake.com/api/bucks";
// export const INFT_SERVER = "https://api.starstake.com/api/inft";
// export const ADMIN_SERVER = "https://api.starstake.com/api/admin";

export const API_CALL_WITHOUT_TOKEN = axios.create({
  baseURL: BASE_URL,
  timeout: 300000,
  headers: { "Content-Type": "application/json" },
  validateStatus: (status) => status > 100,
  transformRequest: [(data) => JSON.stringify(data)],
});

export const API_CALL = axios.create({
  baseURL: BASE_URL,
  timeout: 300000,
  headers: { "Content-Type": "application/json" },
  validateStatus: (status) => status > 100,
  transformRequest: [(data) => JSON.stringify(data)],
});

export const FORM_DATA_API_CALL = axios.create({
  baseURL: BASE_URL,
  timeout: 300000,
  headers: { "Content-Type": "multipart/form-data" },
  validateStatus: (status) => status > 100,
  transformRequest: [(data) => data],
});

API_CALL.interceptors.request.use((config) => {
  if (sessionStorage.getItem("token_object")) {
    const { token } = JSON.parse(sessionStorage.getItem("token_object"));
    config.headers["access-token"] = token;
  }
  return config;
});

FORM_DATA_API_CALL.interceptors.request.use((config) => {
  if (sessionStorage.getItem("token_object")) {
    const { token } = JSON.parse(sessionStorage.getItem("token_object"));
    config.headers["access-token"] = token;
  }
  return config;
});

API_CALL.interceptors.response.use((res) => {
  if (res.status === 401 || res.status === 403) {
    sessionStorage.clear();
    message.error(res.data.error ? res.data.message : "Session Expired !");
    window.location.reload();
  } else if (res.status !== 200 || res.data.error)
    message.error(res.data.message || "Something went wrong");
  return res.data;
});

FORM_DATA_API_CALL.interceptors.response.use((res) => {
  if (res.status === 401 || res.status === 403) {
    sessionStorage.clear();
    message.error(res.data.error ? res.data.message : "Session Expired !");
    window.location.reload();
  } else if (res.status !== 200 || res.data.error)
    message.error(res.data.message || "Something went wrong");
  return res.data;
});

export const defaultParamObj = {
  page: 1,
  limit: 10,
  sort: "createdAt",
  sortBy: -1,
};

export const useGetListApi = ({
  url = "",
  params = defaultParamObj,
  initiateOnLoad = true,
}) => {
  const [loading, setLoading] = useState(false);
  const [paramObj, setParamObj] = useState(params);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (initiateOnLoad) getList();
    return () => resetState();
  }, []);

  const getList = async (params = paramObj) => {
    if (!url || loading) return;
    setLoading(true);
    setParamObj(params);
    delete params.total;
    API_CALL.get(url, { params })
      .then((res) => {
        if (res.status === 200 && res.data?.data?.length) {
          setList(res.data.data);
          const total = res.data.totalCount?.totalRecords || 0;
          setParamObj({ ...params, total: res.data.totalRecords || total });
        } else resetState();
        setLoading(false);
      })
      .catch(() => resetState());
  };

  const resetState = () => {
    setLoading(false);
    setList([]);
    setParamObj(defaultParamObj);
  };

  return { loading, paramObj, getList, list, setList };
};

function formatAfterEncrypt(cipherText) {
  let dataString = cipherText
    .replace(/\+/g, "p1L2u3S")
    .replace(/\//g, "s1L2a3S4h")
    .replace(/=/g, "e1Q2u3A4l");
  return dataString;
}

function getEncrypted(obj) {
  let cipherTextId = AES.encrypt(
    JSON.stringify(obj),
    URL_ENCODING_KEY
  ).toString();
  cipherTextId = formatAfterEncrypt(cipherTextId);
  return cipherTextId;
}

export function getEncryptedUrl(type = "", _id = "") {
  const obj = { [type]: _id, fromPath: "/MarketHub" };
  const route = type === "sNftId" ? "BrandClubDetails" : "AccessNFTDetails";
  return `${MAIN_APP_BASE_URL + route}/${getEncrypted(obj)}`;
}
